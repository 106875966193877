import ReactDOM from 'react-dom/client'
import './utils/i18n/i18n'
import { Suspense } from 'react'
import { ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { ErrorBoundary } from 'react-error-boundary'
import { LocalizationProvider as MUIPickerLocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { ApolloProvider } from '@apollo/client'
import { BrowserRouter } from 'react-router-dom'
import { CacheProvider } from '@emotion/react'
import { apolloClient } from './store/apolloClient'
import { ErrorFallback } from './components/ErrorFallback/ErrorFallback'
import { ThemeSettingsProvider } from './context/ThemeSettingsContext'
import { PageLoader } from './components/PageLoader/PageLoader'
import { Routes } from './routes/Routes'
import { useThemeSettings } from './hooks/useThemeSettings'
import { CognitoAuthProvider } from './context/AuthContext/CognitoAuthContext'
import { emotionCache } from './theme/cache'
import { getLightTheme } from './theme/muiTheme/muiTheme'
import { SnackbarProvider } from './context/SnackbarContext/SnackbarProvider'

import '@fontsource/roboto-mono/300.css'
import '@fontsource/roboto-mono/400.css'
import '@fontsource/roboto-mono/500.css'
import '@fontsource/roboto-mono/700.css'

import '@fontsource/rubik/300.css'
import '@fontsource/rubik/400.css'
import '@fontsource/rubik/500.css'
import '@fontsource/rubik/700.css'

const App: React.FC = () => {
  const { isDarkMode } = useThemeSettings()

  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={isDarkMode ? getLightTheme() : getLightTheme()}>
        <CssBaseline />
        <SnackbarProvider>
          <BrowserRouter>
            <Suspense fallback={<PageLoader fullScreen />}>
              <CognitoAuthProvider fallback={<PageLoader fullScreen />}>
                <Routes />
              </CognitoAuthProvider>
            </Suspense>
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </CacheProvider>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <MUIPickerLocalizationProvider dateAdapter={AdapterLuxon}>
      <ApolloProvider client={apolloClient}>
        <ThemeSettingsProvider>
          <App />
        </ThemeSettingsProvider>
      </ApolloProvider>
    </MUIPickerLocalizationProvider>
  </ErrorBoundary>,
)

import { lighten, type Components, type Theme } from '@mui/material'

declare module '@mui/material/ListItemButton' {
  interface ListItemButtonBaseProps {
    variant?: 'standalone' | null | undefined
  }
  interface ListItemButtonPropsVariantOverrides {
    standalone: true
  }
}

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    inner: true
    outlined: true
    block: true
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    dashed: true
  }
  interface ButtonPropsSizeOverrides {
    extraLarge: true
  }
}

export const components: Components<Omit<Theme, 'components'>> | undefined = {
  MuiCssBaseline: {
    styleOverrides: theme => ({
      '.w-md-editor-preview p': {
        ...theme.typography.body1,
        color: theme.palette.text.primary,
        lineHeight: 1.5,
      },
      '.w-md-editor-preview code': {
        fontSize: '15px !important',
      },
      ul: {
        margin: 0,
        padding: 0,
      },
      li: {
        listStyle: 'none',
      },
    }),
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        borderRadius: '15px',
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: ({ theme }) => ({
        backgroundColor: theme.palette.background.default,
      }),
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: ({ theme }) => ({
        paddingTop: `${theme.spacing(2)} !important`,
      }),
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: ({ theme }) => ({
        paddingRight: `${theme.spacing(3)} !important`,
        paddingBottom: `${theme.spacing(2)} !important`,
      }),
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: ({ theme }) => ({
        background: theme.palette.background.default,
        border: `1px solid ${theme.palette.divider}`,
      }),
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      content: ({ theme }) => ({
        padding: `0 ${theme.spacing(1)}`,
        margin: `${theme.spacing(3)} 0`,
        '&.Mui-expanded': {
          margin: `${theme.spacing(3)} 0`,
        },
      }),
      expandIconWrapper: ({ theme }) => ({
        background: theme.palette.background.paper,
        borderRadius: '50px',
        padding: theme.spacing(0.5),
      }),
    },
  },
  MuiSkeleton: {
    styleOverrides: {
      root: ({ theme }) => ({
        transform: 'none',
      }),
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(3),
        paddingTop: 0,
      }),
    },
  },
  MuiListItemButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(2, 4),
      }),
    },
    variants: [
      {
        props: { variant: 'standalone' },
        style: ({ theme }) => ({
          backgroundColor: theme.palette.background.default,
          borderRadius: 10,
          boxShadow: '0px 4px 5px rgba(166, 140, 215, 0.25)',
          '&.Mui-selected': {
            backgroundColor: theme.palette.background.default,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          },
        }),
      },
    ],
  },
  MuiChip: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: 10,
        backgroundColor: theme.palette.background.default,
        boxShadow: '0px 4px 5px rgba(166, 140, 215, 0.25)',
        ...theme.typography.caption,
      }),
    },
  },
  MuiPaper: {
    defaultProps: {
      elevation: 0,
    },
    // don't add padding to root
    // this will affect other components
    // like Menu, Card etc.
    variants: [
      {
        props: { variant: 'inner' },
        style: ({ theme }) => ({
          borderRadius: 10,
          boxShadow: '0px 4px 5px rgba(166, 140, 215, 0.25)',
          padding: theme.spacing(3, 3.5),
        }),
      },
      {
        props: { variant: 'block' },
        style: ({ theme }) => ({
          padding: theme.spacing(4),
          [theme.breakpoints.down('sm')]: {
            borderRadius: 0,
            padding: theme.spacing(3, 2, 2, 3),
          },
        }),
      },
      {
        props: { variant: 'outlined' },
        style: ({ theme }) => ({
          border: `3px solid ${theme.palette.primary.light}`,
          backgroundColor: theme.palette.background.default,
          padding: theme.spacing(3, 3.5),
        }),
      },
    ],
  },
  MuiCard: {
    styleOverrides: {
      root: ({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.common.white,
      }),
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paper: ({ theme }) => ({
        background: theme.palette.background.default,
      }),
    },
  },
  MuiTabs: {
    styleOverrides: {
      scrollButtons: ({ theme }) => ({
        '&.Mui-disabled': {
          opacity: 0.3,
        },
      }),
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(3),
      }),
    },
  },
  MuiTable: {
    styleOverrides: {
      root: {
        borderCollapse: 'separate',
        borderSpacing: 0,
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        background: theme.palette.background.default,
        borderRadius: `calc(${theme.shape.borderRadius}px - ${theme.spacing(
          1,
        )})`,
      }),
      notchedOutline: ({ theme }) => ({
        borderColor: theme.palette.divider,
      }),
    },
  },
  MuiTextField: {
    defaultProps: {
      required: true,
    },
  },
  MuiInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        gap: theme.spacing(1),
        '&::before': {
          borderColor: theme.palette.grey[400],
        },
      }),
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: ({ theme }) => ({
        backgroundColor: `${theme.palette.background.default} !important`,
        borderRadius: '15px !important',
      }),
    },
  },
  MuiButton: {
    styleOverrides: {
      outlined: ({ ownerState }) => ({
        borderWidth: '2px',
        '&:hover': {
          borderWidth: '2px',
        },
      }),
      root: ({ theme }) => ({
        borderRadius: 15,
        padding: theme.spacing(1.5, 3),
      }),
    },
    defaultProps: {
      disableElevation: true,
    },
    variants: [
      {
        props: { size: 'extraLarge' },
        style: ({ theme }) => ({
          padding: theme.spacing(2.8, 15),
          ...theme.typography.h4,
          [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2.2, 13),
          },
        }),
      },
      {
        props: { size: 'large' },
        style: ({ theme }) => ({
          padding: theme.spacing(1.8, 5),
          ...theme.typography.body1,
        }),
      },
      {
        props: { variant: 'dashed' },
        style: ({ theme }) => ({
          border: `2px dashed ${theme.palette.primary.main}`,
          color: theme.palette.primary.main,
        }),
      },
    ],
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        alignItems: 'center',
      },
    },
  },
  MuiRating: {
    styleOverrides: {
      iconEmpty: ({ theme }) => ({
        color: lighten(theme.palette.text.secondary, 0.3),
      }),
    },
  },
  MuiPaginationItem: {
    styleOverrides: {
      root: ({ theme }) => ({
        minHeight: '40px',
        minWidth: '40px',
        borderRadius: `${theme.shape.borderRadius}px`,
        fontSize: '14px',
      }),
      outlined: ({ theme }) => ({
        background: theme.palette.secondary.main,
      }),
    },
  },
  MuiTypography: {
    styleOverrides: {
      h6: ({ theme }) => ({
        [theme.breakpoints.up('lg')]: {
          fontSize: '1.225rem',
        },
      }),
    },
  },
}

import { createTheme, responsiveFontSizes, type Theme } from '@mui/material'
import { shape } from './shape'
import { typography } from './typography'
import { components } from './components'
import { breakpoints } from './breakpoints'
import { lightPalette } from './palette/lightPalette'
import { darkPalette } from './palette/darkPalette'

const muiTheme = responsiveFontSizes(
  createTheme({
    breakpoints,
    components,
    shape,
    typography,
  }),
)

export const getLightTheme = (): Theme =>
  createTheme(muiTheme, {
    palette: {
      mode: 'light',
      ...lightPalette,
    },
  })

export const getDarkTheme = (): Theme =>
  createTheme(muiTheme, {
    palette: {
      mode: 'dark',
      ...darkPalette,
    },
  })

import { type TypographyOptions } from '@mui/material/styles/createTypography'

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    prompt: true
  }
}

export const typography: TypographyOptions = {
  fontFamily: ['Rubik', 'sans-serif', 'Roboto Mono', 'monospace'].join(','),
  h1: {
    fontFamily: "Roboto Mono, 'monospace'",
    fontSize: '4.5rem', // 72px
    lineHeight: '1.1',
    fontWeight: 700,
  },
  h2: {
    fontFamily: "Roboto Mono, 'monospace'",
    fontSize: '2.125rem', // 34px
    lineHeight: '1.32',
    fontWeight: 700,
  },
  h3: {
    fontFamily: "Rubik, 'sans-serif'",
    fontSize: '1.525rem', // 26px
    lineHeight: '1.15',
    fontWeight: 500,
  },
  h4: {
    fontFamily: "Rubik, 'sans-serif'",
    fontSize: '1.4rem', // 24px
    lineHeight: '1.1875',
    fontWeight: 400,
  },
  h5: {
    fontFamily: "Roboto Mono, 'monospace'",
    fontSize: '1.275rem', // 24px
    lineHeight: '1.1',
    fontWeight: 500,
  },
  h6: {
    fontFamily: "Rubik, 'sans-serif'",
    fontSize: '1.275rem', // 22px
    lineHeight: '1.375',
    fontWeight: 400,
  },
  subtitle1: {
    fontFamily: "Roboto Mono, 'monospace'",
    fontSize: '1.125rem', // 18px
    lineHeight: '1.1',
    fontWeight: 400,
  },
  subtitle2: {
    fontFamily: "Roboto Mono, 'monospace'",
    fontSize: '1.125rem', // 18px
    lineHeight: '1.1',
    fontWeight: 300,
  },
  body1: {
    fontFamily: "Rubik, 'sans-serif'",
    fontSize: '1.125rem', // 20px
    lineHeight: '1.25',
    fontWeight: 400,
  },
  body2: {
    fontFamily: "Rubik, 'sans-serif'",
    fontSize: '1.075rem', // 18px
    lineHeight: '1.2',
    fontWeight: 400,
  },
  // @ts-expect-error custom variant
  prompt: {
    fontSize: '1.125rem',
    fontWeight: 400,
    lineHeight: '1.325',
  },
  button: {
    fontFamily: "Rubik, 'sans-serif'",
    fontSize: '1.125rem', // 20px
    lineHeight: '1.2',
    fontWeight: 400,
    textTransform: 'none',
  },
  caption: {
    fontFamily: "Rubik, 'sans-serif'",
    fontSize: '1rem', // 16px
    lineHeight: '1.1875',
    fontWeight: 400,
  },
}

import { useMediaQuery, useTheme } from '@mui/material'

interface Resolutions {
  isMobileResolution: boolean
  isLargeMobileResolution: boolean
  isDesktopResolution: boolean
  isLaptopResolution: boolean
  isTabledResolution: boolean
}

export const useResolutions = (): Resolutions => {
  const theme = useTheme()
  return {
    isMobileResolution: useMediaQuery(theme.breakpoints.down('sm')),
    isLargeMobileResolution: useMediaQuery(theme.breakpoints.down('md')),
    isTabledResolution: useMediaQuery(theme.breakpoints.down('lg')),
    isLaptopResolution: useMediaQuery(theme.breakpoints.down('xl')),
    isDesktopResolution: useMediaQuery(theme.breakpoints.up('xl')),
  }
}

import { createRoute } from '../utils/createRoute'

export const getForgotPasswordPageRoute = createRoute('/forgot-password')
export const getLoginRoute = createRoute('/login')
export const getSignUpRoute = createRoute('/signup')
export const getNotFoundRoute = createRoute('/404')

export const getMainPageRoute = createRoute('/')

export const getToolsPageRoute = createRoute('/tools')
export const getToolDetailsPageRoute = createRoute('/tools/:id')
export const getCreateAndReleaseToolPageRoute = createRoute('/tools/new')
export const getUpdateToolPageRoute = createRoute('/tools/:id/update')
export const getToolReleasesPageRoute = createRoute('/tools/:id/releases')

export const getUserInfoPageRoute = createRoute('/:id')
export const getUserToolsPageRoute = createRoute('/:id/tools')
export const getUserApiKeysPageRoute = createRoute('/:id/api-keys')
export const getUserSettingsPageRoute = createRoute('/:id/settings')

export const getChatPageRoute = createRoute('/chat')
export const getContactPageRoute = createRoute('/contact')
export const getPrivacyPageRoute = createRoute('/privacy')
export const getCareersPageRoute = createRoute('/careers')
export const getOtherPageRoute = createRoute('/others')
export const getWaitlistPageRoute = createRoute('/waitlist')

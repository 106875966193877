import { createContext } from 'react'
import { useDarkMode, useLocalStorage } from 'usehooks-ts'

export interface ThemeSettingsContextInterface {
  isContainerFixed: boolean
  isSubheaderShown: boolean
  isSidebarShown: boolean
  isSidebarEnabled: boolean
  isLayoutPinned: boolean
  isDarkMode: boolean
  toggleContainerFixed: () => void
  toggleSubheaderShown: () => void
  toggleSidebarShown: () => void
  toggleSidebarEnabled: () => void
  toggleLayoutPinned: () => void
  toggleDarkModeEnabled: () => void
  toggleRTLEnabled: () => void
  toggleFullScreenEnabled: () => void
}

export const ThemeSettingsContext =
  createContext<ThemeSettingsContextInterface | null>(null)

interface Props {
  children: React.ReactElement
}

export const ThemeSettingsProvider: React.FC<Props> = ({ children }) => {
  const [isContainerFixed, setIsContainerFixed] = useLocalStorage(
    'theme-is-container-fixed',
    false,
  )
  const [isSubheaderShown, setIsSubheaderShown] = useLocalStorage(
    'theme-is-subheader-show',
    true,
  )
  const [isSidebarEnabled, setIsSidebarEnabled] = useLocalStorage(
    'theme-is-sidebar-enabled',
    true,
  )
  const [isSidebarShown, setIsSidebarShown] = useLocalStorage(
    'theme-is-sidebar-shown',
    true,
  )
  const [isLayoutPinned, setIsLayoutPinned] = useLocalStorage(
    'theme-is-layout-pinned',
    false,
  )
  const [isRTLEnabled, setIsRTLEnabled] = useLocalStorage(
    'theme-is-rtl-enabled',
    false,
  )
  const { isDarkMode, toggle: toggleDarkModeEnabled } = useDarkMode(false)

  const toggleContainerFixed = (): void => {
    setIsContainerFixed(!isContainerFixed)
  }

  const toggleSubheaderShown = (): void => {
    setIsSubheaderShown(!isSubheaderShown)
  }

  const toggleSidebarShown = (): void => {
    setIsSidebarShown(!isSidebarShown)
  }

  const toggleLayoutPinned = (): void => {
    setIsLayoutPinned(!isLayoutPinned)
  }

  const toggleSidebarEnabled = (): void => {
    setIsSidebarEnabled(!isSidebarEnabled)
  }

  const toggleRTLEnabled = (): void => {
    setIsRTLEnabled(!isRTLEnabled)
  }

  const toggleFullScreenEnabled = async (): Promise<void> => {
    if (document.fullscreenElement != null) {
      await document.exitFullscreen()
    } else {
      await document.documentElement.requestFullscreen()
    }
  }

  return (
    <ThemeSettingsContext.Provider
      value={{
        isContainerFixed,
        isDarkMode,
        isLayoutPinned,
        isSidebarEnabled,
        isSidebarShown,
        isSubheaderShown,
        toggleContainerFixed,
        toggleDarkModeEnabled,
        toggleFullScreenEnabled,
        toggleLayoutPinned,
        toggleRTLEnabled,
        toggleSidebarEnabled,
        toggleSidebarShown,
        toggleSubheaderShown,
      }}
    >
      {children}
    </ThemeSettingsContext.Provider>
  )
}

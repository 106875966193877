import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  List,
  ListItem,
  Paper,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  getChatPageRoute,
  getLoginRoute,
  getMainPageRoute,
  getSignUpRoute,
  getToolsPageRoute,
  getUserInfoPageRoute,
} from '../../../../routes'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthGuard } from '../../../../guards/AuthGuard/AuthGuard'
import { GuestGuard } from '../../../../guards/GuestGuard/GuestGuard'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import { ReactComponent as AppleWrenchIcon } from '../../../../resources/icons/wrench.svg'
import { NavLink } from '../../../../components/NavLink/NavLink'
import { useCognitoAuthContext } from '../../../../hooks/useCognitoAuthContext'

export interface HeaderDesktopNavItem {
  to: string
  label: string
}

interface Props {
  position: 'fixed' | 'absolute'
}

export const HeaderSimple: React.FC<Props> = ({ position }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { currentUser } = useCognitoAuthContext()

  const navItems: HeaderDesktopNavItem[] = [
    {
      to: getToolsPageRoute.path,
      label: t('pageTitle.toolsPage'),
    },
    {
      to: getChatPageRoute.path,
      label: t('pageTitle.chatPage'),
    },
  ]

  return (
    <AppBar
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        position,
        top: 35,
        left: 0,
        backgroundColor: 'transparent',
        boxShadow: 'none',
      }}
    >
      <Container maxWidth="xl">
        <Paper
          sx={{
            background: 'transparent',
            px: {
              xs: 3,
              md: 6,
            },
            py: 0.5,
          }}
        >
          <Toolbar disableGutters>
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={1}
            >
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  gap: {
                    xs: 2,
                    md: 5,
                  },
                }}
              >
                <Box
                  onClick={() => {
                    navigate(getMainPageRoute.path)
                  }}
                  display="flex"
                  alignItems="center"
                  gap={2}
                  sx={{ cursor: 'pointer' }}
                >
                  <AppleWrenchIcon />
                  <Typography variant="h4" fontWeight={500}>
                    tbox.ai
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Toolbar>
        </Paper>
      </Container>
    </AppBar>
  )
}

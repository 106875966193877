import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack'
import { css, styled, type Theme } from '@mui/material'
import { ReactComponent as SuccessIcon } from '../../resources/icons/snackbar-success.svg'
import { ReactComponent as ErrorIcon } from '../../resources/icons/snackbar-error.svg'
import { ReactComponent as WarningIcon } from '../../resources/icons/snackbar-warning.svg'
import { ReactComponent as InfoIcon } from '../../resources/icons/snackbar-info.svg'
import { type SerializedStyles } from '@emotion/react'

const getColorsOverride = (
  variant: 'success' | 'warning' | 'info' | 'error',
  theme: Theme,
): SerializedStyles => css`
  &.notistack-MuiContent-${variant} {
    background-color: ${theme.palette[variant].main};
    border-radius: 15px;
    svg {
      color: ${theme.palette.background.default};
      width: 25px;
      height: 25px;
    }
  }
`

export const StyledSnackbarProvider = styled(NotistackSnackbarProvider)`
  #notistack-snackbar {
    gap: ${({ theme }) => theme.spacing(1.5)};
    font-size: ${({ theme }) => theme.typography.body1.fontSize};
    line-height: ${({ theme }) => theme.typography.h6.lineHeight};
    padding: 10px 8px 10px 0;
  }

  &.SnackbarItem-contentRoot {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12);
  }

  ${({ theme }) => getColorsOverride('success', theme)};
  ${({ theme }) => getColorsOverride('warning', theme)};
  ${({ theme }) => getColorsOverride('info', theme)};
  ${({ theme }) => getColorsOverride('error', theme)};
`

interface Props {
  children: React.ReactNode
}

export const SnackbarProvider: React.FC<Props> = ({ children }) => (
  <StyledSnackbarProvider
    iconVariant={{
      error: <ErrorIcon />,
      info: <InfoIcon />,
      success: <SuccessIcon />,
      warning: <WarningIcon />,
    }}
    autoHideDuration={3000}
    maxSnack={3}
  >
    {children}
  </StyledSnackbarProvider>
)

import { pathToRegexp, compile } from 'path-to-regexp'

export const createRoute = (path: string): any => {
  const pathRe = pathToRegexp(path)
  const toPath = compile(path)
  const fn = (options?: any): string => toPath(options)

  fn.path = path
  fn.exec = (str: string) => pathRe.exec(str)

  return fn
}

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: { input: any; output: any; }
};

export type ApiKey = {
  __typename: 'ApiKey';
  createdAt: Scalars['DateTime']['output'];
  createdById: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  lastUsedAt: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ApiKeyMutations = {
  __typename: 'ApiKeyMutations';
  create: ApiKey;
  remove: Scalars['Boolean']['output'];
  update: ApiKey;
};


export type ApiKeyMutationsCreateArgs = {
  data: CreateApiKeyDto;
};


export type ApiKeyMutationsRemoveArgs = {
  apiKeyId: Scalars['String']['input'];
};


export type ApiKeyMutationsUpdateArgs = {
  apiKeyId: Scalars['String']['input'];
  data: UpdateApiKeyDto;
};

export type ApiKeyReturnType = {
  __typename: 'ApiKeyReturnType';
  count: Scalars['Int']['output'];
  records: Array<ApiKey>;
};

export type ApiKeyReveal = {
  __typename: 'ApiKeyReveal';
  id: Scalars['ID']['output'];
  revealedAt: Scalars['DateTime']['output'];
};

export type CreateApiKeyDto = {
  name: Scalars['String']['input'];
};

export type CreateFileObjectDto = {
  contentType?: InputMaybe<Scalars['String']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
};

export type CreateReviewDto = {
  rating: Scalars['Float']['input'];
};

export type CreateSupportTicketDto = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  subject: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export type CreateTagDto = {
  name: Scalars['String']['input'];
};

export type CreateToolAndReleaseDto = {
  description?: InputMaybe<Scalars['String']['input']>;
  fileObjectId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  price?: InputMaybe<Scalars['Float']['input']>;
  prompt: Scalars['String']['input'];
  tagIds?: InputMaybe<Array<Scalars['String']['input']>>;
  text?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type CreateToolReleaseDto = {
  fileObjectId: Scalars['String']['input'];
  prompt: Scalars['String']['input'];
  releaseType: ReleaseType;
  text?: InputMaybe<Scalars['String']['input']>;
  toolId: Scalars['String']['input'];
};

export type FileObject = {
  __typename: 'FileObject';
  createdAt: Scalars['DateTime']['output'];
  createdById?: Maybe<Scalars['String']['output']>;
  downloadURL: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mime: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uploadURL: Scalars['String']['output'];
  uploadedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FileObjectMutations = {
  __typename: 'FileObjectMutations';
  create: FileObject;
};


export type FileObjectMutationsCreateArgs = {
  data: CreateFileObjectDto;
};

export type Mutation = {
  __typename: 'Mutation';
  apiKeyMutations: ApiKeyMutations;
  fileObjectMutations: FileObjectMutations;
  supportTicketMutations: SupportTicketMutations;
  tagMutations: TagMutations;
  toolMutations: ToolMutations;
  toolReleaseMutations: ToolReleaseMutations;
};

export type Query = {
  __typename: 'Query';
  apiKey?: Maybe<ApiKey>;
  apiKeysPaged: ApiKeyReturnType;
  fileObject?: Maybe<FileObject>;
  tag?: Maybe<Tag>;
  tagsPaged: TagReturnType;
  tool?: Maybe<Tool>;
  toolRelease?: Maybe<ToolRelease>;
  toolReleasesPaged: ToolReleasesReturnType;
  toolsOverview: ToolsOverview;
  toolsPaged: ToolReturnType;
  user: User;
};


export type QueryApiKeyArgs = {
  apiKeyId: Scalars['String']['input'];
};


export type QueryApiKeysPagedArgs = {
  includeOwn?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Float']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  take?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryFileObjectArgs = {
  id: Scalars['String']['input'];
};


export type QueryTagArgs = {
  tagId: Scalars['String']['input'];
};


export type QueryTagsPagedArgs = {
  excludeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  includeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  skip?: InputMaybe<Scalars['Float']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  take?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryToolArgs = {
  toolId: Scalars['String']['input'];
};


export type QueryToolReleaseArgs = {
  toolReleaseId: Scalars['String']['input'];
};


export type QueryToolReleasesPagedArgs = {
  excludeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  includeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  skip?: InputMaybe<Scalars['Float']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  take?: InputMaybe<Scalars['Float']['input']>;
  toolIds?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryToolsOverviewArgs = {
  toolIds?: InputMaybe<Array<Scalars['String']['input']>>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryToolsPagedArgs = {
  excludeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  includeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  includeOwned?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Float']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  tagIds?: InputMaybe<Array<Scalars['String']['input']>>;
  take?: InputMaybe<Scalars['Float']['input']>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryUserArgs = {
  userId: Scalars['String']['input'];
};

export enum ReleaseType {
  Major = 'Major',
  Minor = 'Minor'
}

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SupportTicketMutations = {
  __typename: 'SupportTicketMutations';
  create: Scalars['Boolean']['output'];
};


export type SupportTicketMutationsCreateArgs = {
  data: CreateSupportTicketDto;
};

export type Tag = {
  __typename: 'Tag';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TagMutations = {
  __typename: 'TagMutations';
  create: Tag;
  remove: Scalars['Boolean']['output'];
  update: Tag;
};


export type TagMutationsCreateArgs = {
  data: CreateTagDto;
};


export type TagMutationsRemoveArgs = {
  tagId: Scalars['String']['input'];
};


export type TagMutationsUpdateArgs = {
  data: UpdateTagDto;
  tagId: Scalars['String']['input'];
};

export type TagReturnType = {
  __typename: 'TagReturnType';
  count: Scalars['Int']['output'];
  records: Array<Tag>;
};

export type Tool = {
  __typename: 'Tool';
  createdAt: Scalars['DateTime']['output'];
  createdById?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  hasReviewFromCurrentUser: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  latestRelease?: Maybe<ToolRelease>;
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  rating: Scalars['Float']['output'];
  releases: Array<ToolRelease>;
  tags: Array<Tag>;
  text?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  viewsCount: Scalars['Int']['output'];
};

export type ToolMutations = {
  __typename: 'ToolMutations';
  addReview: Tool;
  createAndRelease: Tool;
  remove: Scalars['Boolean']['output'];
  update: Tool;
};


export type ToolMutationsAddReviewArgs = {
  input: CreateReviewDto;
  toolId: Scalars['String']['input'];
};


export type ToolMutationsCreateAndReleaseArgs = {
  data: CreateToolAndReleaseDto;
};


export type ToolMutationsRemoveArgs = {
  toolId: Scalars['String']['input'];
};


export type ToolMutationsUpdateArgs = {
  data: UpdateToolDto;
  toolId: Scalars['String']['input'];
};

export type ToolRelease = {
  __typename: 'ToolRelease';
  createdAt: Scalars['DateTime']['output'];
  createdById?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  fileObjects: Array<FileObject>;
  id: Scalars['ID']['output'];
  prompt: Scalars['String']['output'];
  text?: Maybe<Scalars['String']['output']>;
  toolId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
};

export type ToolReleaseMutations = {
  __typename: 'ToolReleaseMutations';
  create: ToolRelease;
};


export type ToolReleaseMutationsCreateArgs = {
  data: CreateToolReleaseDto;
};

export type ToolReleasesReturnType = {
  __typename: 'ToolReleasesReturnType';
  count: Scalars['Int']['output'];
  records: Array<ToolRelease>;
};

export type ToolReturnType = {
  __typename: 'ToolReturnType';
  count: Scalars['Int']['output'];
  records: Array<Tool>;
};

export type ToolsOverview = {
  __typename: 'ToolsOverview';
  ratingAverage: Scalars['Float']['output'];
  requestsTotal: Scalars['Int']['output'];
  viewsTotal: Scalars['Int']['output'];
};

export type UpdateApiKeyDto = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTagDto = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateToolDto = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  tagIds?: InputMaybe<Array<Scalars['String']['input']>>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename: 'User';
  id: Scalars['ID']['output'];
  nickname: Scalars['String']['output'];
};

export type ApiKeyFragment = { __typename: 'ApiKey', id: string, name: string, createdAt: any, lastUsedAt: any };

export type ApiKeysPagedQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  includeOwn?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ApiKeysPagedQuery = { __typename: 'Query', apiKeysPaged: { __typename: 'ApiKeyReturnType', count: number, records: Array<{ __typename: 'ApiKey', id: string, name: string, createdAt: any, lastUsedAt: any }> } };

export type CreateApiKeyMutationVariables = Exact<{
  data: CreateApiKeyDto;
}>;


export type CreateApiKeyMutation = { __typename: 'Mutation', apiKeyMutations: { __typename: 'ApiKeyMutations', create: { __typename: 'ApiKey', value: string, id: string, name: string, createdAt: any, lastUsedAt: any } } };

export type UpdateApiKeyMutationVariables = Exact<{
  data: UpdateApiKeyDto;
  apiKeyId: Scalars['String']['input'];
}>;


export type UpdateApiKeyMutation = { __typename: 'Mutation', apiKeyMutations: { __typename: 'ApiKeyMutations', update: { __typename: 'ApiKey', id: string, name: string, createdAt: any, lastUsedAt: any } } };

export type RemoveApiKeyMutationVariables = Exact<{
  apiKeyId: Scalars['String']['input'];
}>;


export type RemoveApiKeyMutation = { __typename: 'Mutation', apiKeyMutations: { __typename: 'ApiKeyMutations', remove: boolean } };

export type CreateFileObjectMutationVariables = Exact<{
  data: CreateFileObjectDto;
}>;


export type CreateFileObjectMutation = { __typename: 'Mutation', fileObjectMutations: { __typename: 'FileObjectMutations', create: { __typename: 'FileObject', uploadURL: string, downloadURL: string, name: string, id: string } } };

export type CreateSupportTicketMutationVariables = Exact<{
  data: CreateSupportTicketDto;
}>;


export type CreateSupportTicketMutation = { __typename: 'Mutation', supportTicketMutations: { __typename: 'SupportTicketMutations', create: boolean } };

export type TagFragment = { __typename: 'Tag', id: string, name: string };

export type TagsPagedQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  excludeIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  includeIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;


export type TagsPagedQuery = { __typename: 'Query', tagsPaged: { __typename: 'TagReturnType', count: number, records: Array<{ __typename: 'Tag', id: string, name: string }> } };

export type ToolCardFragment = { __typename: 'Tool', id: string, name: string, title: string, description?: string | null, price: number, rating: number, hasReviewFromCurrentUser: boolean, user: { __typename: 'User', nickname: string, id: string } };

export type ToolDetailsFragment = { __typename: 'Tool', text?: string | null, createdById?: string | null, viewsCount: number, createdAt: any, updatedAt: any, id: string, name: string, title: string, description?: string | null, price: number, rating: number, hasReviewFromCurrentUser: boolean, releases: Array<{ __typename: 'ToolRelease', id: string, version: number, updatedAt: any }>, latestRelease?: { __typename: 'ToolRelease', prompt: string, fileObjects: Array<{ __typename: 'FileObject', id: string, name: string, downloadURL: string }> } | null, tags: Array<{ __typename: 'Tag', id: string, name: string }>, user: { __typename: 'User', nickname: string, id: string } };

export type ToolDetailsQueryVariables = Exact<{
  toolId: Scalars['String']['input'];
}>;


export type ToolDetailsQuery = { __typename: 'Query', tool?: { __typename: 'Tool', text?: string | null, createdById?: string | null, viewsCount: number, createdAt: any, updatedAt: any, id: string, name: string, title: string, description?: string | null, price: number, rating: number, hasReviewFromCurrentUser: boolean, releases: Array<{ __typename: 'ToolRelease', id: string, version: number, updatedAt: any }>, latestRelease?: { __typename: 'ToolRelease', prompt: string, fileObjects: Array<{ __typename: 'FileObject', id: string, name: string, downloadURL: string }> } | null, tags: Array<{ __typename: 'Tag', id: string, name: string }>, user: { __typename: 'User', nickname: string, id: string } } | null };

export type ToolsPagedQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  excludeIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  includeIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  tagIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  includeOwned?: InputMaybe<Scalars['Boolean']['input']>;
  userIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;


export type ToolsPagedQuery = { __typename: 'Query', toolsPaged: { __typename: 'ToolReturnType', count: number, records: Array<{ __typename: 'Tool', id: string, name: string, title: string, description?: string | null, price: number, rating: number, hasReviewFromCurrentUser: boolean, user: { __typename: 'User', nickname: string, id: string } }> } };

export type CreateAndReleaseToolMutationVariables = Exact<{
  data: CreateToolAndReleaseDto;
}>;


export type CreateAndReleaseToolMutation = { __typename: 'Mutation', toolMutations: { __typename: 'ToolMutations', createAndRelease: { __typename: 'Tool', id: string, name: string, title: string, description?: string | null, price: number, rating: number, hasReviewFromCurrentUser: boolean, user: { __typename: 'User', nickname: string, id: string } } } };

export type UpdatedToolMutationVariables = Exact<{
  data: UpdateToolDto;
  toolId: Scalars['String']['input'];
}>;


export type UpdatedToolMutation = { __typename: 'Mutation', toolMutations: { __typename: 'ToolMutations', update: { __typename: 'Tool', text?: string | null, createdById?: string | null, viewsCount: number, createdAt: any, updatedAt: any, id: string, name: string, title: string, description?: string | null, price: number, rating: number, hasReviewFromCurrentUser: boolean, releases: Array<{ __typename: 'ToolRelease', id: string, version: number, updatedAt: any }>, latestRelease?: { __typename: 'ToolRelease', prompt: string, fileObjects: Array<{ __typename: 'FileObject', id: string, name: string, downloadURL: string }> } | null, tags: Array<{ __typename: 'Tag', id: string, name: string }>, user: { __typename: 'User', nickname: string, id: string } } } };

export type RemoveToolMutationVariables = Exact<{
  toolId: Scalars['String']['input'];
}>;


export type RemoveToolMutation = { __typename: 'Mutation', toolMutations: { __typename: 'ToolMutations', remove: boolean } };

export type AddToolReviewMutationVariables = Exact<{
  input: CreateReviewDto;
  toolId: Scalars['String']['input'];
}>;


export type AddToolReviewMutation = { __typename: 'Mutation', toolMutations: { __typename: 'ToolMutations', addReview: { __typename: 'Tool', id: string, rating: number, hasReviewFromCurrentUser: boolean } } };

export type UserAccountQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type UserAccountQuery = { __typename: 'Query', user: { __typename: 'User', nickname: string } };

export type UserToolsOverviewQueryVariables = Exact<{
  userIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type UserToolsOverviewQuery = { __typename: 'Query', toolsOverview: { __typename: 'ToolsOverview', viewsTotal: number, ratingAverage: number, requestsTotal: number } };

export const ApiKeyFragmentDoc = gql`
    fragment ApiKey on ApiKey {
  id
  name
  createdAt
  lastUsedAt
}
    `;
export const TagFragmentDoc = gql`
    fragment Tag on Tag {
  id
  name
}
    `;
export const ToolCardFragmentDoc = gql`
    fragment ToolCard on Tool {
  id
  name
  title
  description
  price
  rating
  hasReviewFromCurrentUser
  user {
    nickname
    id
  }
}
    `;
export const ToolDetailsFragmentDoc = gql`
    fragment ToolDetails on Tool {
  ...ToolCard
  text
  releases {
    id
    version
    updatedAt
  }
  createdById
  latestRelease {
    prompt
    fileObjects {
      id
      name
      downloadURL
    }
  }
  tags {
    id
    name
  }
  viewsCount
  createdAt
  updatedAt
}
    ${ToolCardFragmentDoc}`;
export const ApiKeysPagedDocument = gql`
    query ApiKeysPaged($skip: Float, $take: Float, $sortBy: String, $sortDirection: SortDirection, $includeOwn: Boolean) {
  apiKeysPaged(
    skip: $skip
    take: $take
    sortBy: $sortBy
    sortDirection: $sortDirection
    includeOwn: $includeOwn
  ) {
    records {
      ...ApiKey
    }
    count
  }
}
    ${ApiKeyFragmentDoc}`;

/**
 * __useApiKeysPagedQuery__
 *
 * To run a query within a React component, call `useApiKeysPagedQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiKeysPagedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiKeysPagedQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      includeOwn: // value for 'includeOwn'
 *   },
 * });
 */
export function useApiKeysPagedQuery(baseOptions?: Apollo.QueryHookOptions<ApiKeysPagedQuery, ApiKeysPagedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiKeysPagedQuery, ApiKeysPagedQueryVariables>(ApiKeysPagedDocument, options);
      }
export function useApiKeysPagedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiKeysPagedQuery, ApiKeysPagedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiKeysPagedQuery, ApiKeysPagedQueryVariables>(ApiKeysPagedDocument, options);
        }
export type ApiKeysPagedQueryHookResult = ReturnType<typeof useApiKeysPagedQuery>;
export type ApiKeysPagedLazyQueryHookResult = ReturnType<typeof useApiKeysPagedLazyQuery>;
export type ApiKeysPagedQueryResult = Apollo.QueryResult<ApiKeysPagedQuery, ApiKeysPagedQueryVariables>;
export const CreateApiKeyDocument = gql`
    mutation CreateApiKey($data: CreateApiKeyDTO!) {
  apiKeyMutations {
    create(data: $data) {
      ...ApiKey
      value
    }
  }
}
    ${ApiKeyFragmentDoc}`;
export type CreateApiKeyMutationFn = Apollo.MutationFunction<CreateApiKeyMutation, CreateApiKeyMutationVariables>;

/**
 * __useCreateApiKeyMutation__
 *
 * To run a mutation, you first call `useCreateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApiKeyMutation, { data, loading, error }] = useCreateApiKeyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<CreateApiKeyMutation, CreateApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateApiKeyMutation, CreateApiKeyMutationVariables>(CreateApiKeyDocument, options);
      }
export type CreateApiKeyMutationHookResult = ReturnType<typeof useCreateApiKeyMutation>;
export type CreateApiKeyMutationResult = Apollo.MutationResult<CreateApiKeyMutation>;
export type CreateApiKeyMutationOptions = Apollo.BaseMutationOptions<CreateApiKeyMutation, CreateApiKeyMutationVariables>;
export const UpdateApiKeyDocument = gql`
    mutation UpdateApiKey($data: UpdateApiKeyDTO!, $apiKeyId: String!) {
  apiKeyMutations {
    update(data: $data, apiKeyId: $apiKeyId) {
      ...ApiKey
    }
  }
}
    ${ApiKeyFragmentDoc}`;
export type UpdateApiKeyMutationFn = Apollo.MutationFunction<UpdateApiKeyMutation, UpdateApiKeyMutationVariables>;

/**
 * __useUpdateApiKeyMutation__
 *
 * To run a mutation, you first call `useUpdateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApiKeyMutation, { data, loading, error }] = useUpdateApiKeyMutation({
 *   variables: {
 *      data: // value for 'data'
 *      apiKeyId: // value for 'apiKeyId'
 *   },
 * });
 */
export function useUpdateApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApiKeyMutation, UpdateApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateApiKeyMutation, UpdateApiKeyMutationVariables>(UpdateApiKeyDocument, options);
      }
export type UpdateApiKeyMutationHookResult = ReturnType<typeof useUpdateApiKeyMutation>;
export type UpdateApiKeyMutationResult = Apollo.MutationResult<UpdateApiKeyMutation>;
export type UpdateApiKeyMutationOptions = Apollo.BaseMutationOptions<UpdateApiKeyMutation, UpdateApiKeyMutationVariables>;
export const RemoveApiKeyDocument = gql`
    mutation RemoveApiKey($apiKeyId: String!) {
  apiKeyMutations {
    remove(apiKeyId: $apiKeyId)
  }
}
    `;
export type RemoveApiKeyMutationFn = Apollo.MutationFunction<RemoveApiKeyMutation, RemoveApiKeyMutationVariables>;

/**
 * __useRemoveApiKeyMutation__
 *
 * To run a mutation, you first call `useRemoveApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeApiKeyMutation, { data, loading, error }] = useRemoveApiKeyMutation({
 *   variables: {
 *      apiKeyId: // value for 'apiKeyId'
 *   },
 * });
 */
export function useRemoveApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<RemoveApiKeyMutation, RemoveApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveApiKeyMutation, RemoveApiKeyMutationVariables>(RemoveApiKeyDocument, options);
      }
export type RemoveApiKeyMutationHookResult = ReturnType<typeof useRemoveApiKeyMutation>;
export type RemoveApiKeyMutationResult = Apollo.MutationResult<RemoveApiKeyMutation>;
export type RemoveApiKeyMutationOptions = Apollo.BaseMutationOptions<RemoveApiKeyMutation, RemoveApiKeyMutationVariables>;
export const CreateFileObjectDocument = gql`
    mutation CreateFileObject($data: CreateFileObjectDTO!) {
  fileObjectMutations {
    create(data: $data) {
      uploadURL
      downloadURL
      name
      id
    }
  }
}
    `;
export type CreateFileObjectMutationFn = Apollo.MutationFunction<CreateFileObjectMutation, CreateFileObjectMutationVariables>;

/**
 * __useCreateFileObjectMutation__
 *
 * To run a mutation, you first call `useCreateFileObjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFileObjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFileObjectMutation, { data, loading, error }] = useCreateFileObjectMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateFileObjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateFileObjectMutation, CreateFileObjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFileObjectMutation, CreateFileObjectMutationVariables>(CreateFileObjectDocument, options);
      }
export type CreateFileObjectMutationHookResult = ReturnType<typeof useCreateFileObjectMutation>;
export type CreateFileObjectMutationResult = Apollo.MutationResult<CreateFileObjectMutation>;
export type CreateFileObjectMutationOptions = Apollo.BaseMutationOptions<CreateFileObjectMutation, CreateFileObjectMutationVariables>;
export const CreateSupportTicketDocument = gql`
    mutation CreateSupportTicket($data: CreateSupportTicketDTO!) {
  supportTicketMutations {
    create(data: $data)
  }
}
    `;
export type CreateSupportTicketMutationFn = Apollo.MutationFunction<CreateSupportTicketMutation, CreateSupportTicketMutationVariables>;

/**
 * __useCreateSupportTicketMutation__
 *
 * To run a mutation, you first call `useCreateSupportTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupportTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupportTicketMutation, { data, loading, error }] = useCreateSupportTicketMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSupportTicketMutation(baseOptions?: Apollo.MutationHookOptions<CreateSupportTicketMutation, CreateSupportTicketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSupportTicketMutation, CreateSupportTicketMutationVariables>(CreateSupportTicketDocument, options);
      }
export type CreateSupportTicketMutationHookResult = ReturnType<typeof useCreateSupportTicketMutation>;
export type CreateSupportTicketMutationResult = Apollo.MutationResult<CreateSupportTicketMutation>;
export type CreateSupportTicketMutationOptions = Apollo.BaseMutationOptions<CreateSupportTicketMutation, CreateSupportTicketMutationVariables>;
export const TagsPagedDocument = gql`
    query TagsPaged($skip: Float, $take: Float, $sortBy: String, $sortDirection: SortDirection, $excludeIds: [String!], $includeIds: [String!]) {
  tagsPaged(
    skip: $skip
    take: $take
    sortBy: $sortBy
    sortDirection: $sortDirection
    excludeIds: $excludeIds
    includeIds: $includeIds
  ) {
    records {
      ...Tag
    }
    count
  }
}
    ${TagFragmentDoc}`;

/**
 * __useTagsPagedQuery__
 *
 * To run a query within a React component, call `useTagsPagedQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsPagedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsPagedQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      excludeIds: // value for 'excludeIds'
 *      includeIds: // value for 'includeIds'
 *   },
 * });
 */
export function useTagsPagedQuery(baseOptions?: Apollo.QueryHookOptions<TagsPagedQuery, TagsPagedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagsPagedQuery, TagsPagedQueryVariables>(TagsPagedDocument, options);
      }
export function useTagsPagedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagsPagedQuery, TagsPagedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagsPagedQuery, TagsPagedQueryVariables>(TagsPagedDocument, options);
        }
export type TagsPagedQueryHookResult = ReturnType<typeof useTagsPagedQuery>;
export type TagsPagedLazyQueryHookResult = ReturnType<typeof useTagsPagedLazyQuery>;
export type TagsPagedQueryResult = Apollo.QueryResult<TagsPagedQuery, TagsPagedQueryVariables>;
export const ToolDetailsDocument = gql`
    query ToolDetails($toolId: String!) {
  tool(toolId: $toolId) {
    ...ToolDetails
  }
}
    ${ToolDetailsFragmentDoc}`;

/**
 * __useToolDetailsQuery__
 *
 * To run a query within a React component, call `useToolDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useToolDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useToolDetailsQuery({
 *   variables: {
 *      toolId: // value for 'toolId'
 *   },
 * });
 */
export function useToolDetailsQuery(baseOptions: Apollo.QueryHookOptions<ToolDetailsQuery, ToolDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ToolDetailsQuery, ToolDetailsQueryVariables>(ToolDetailsDocument, options);
      }
export function useToolDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ToolDetailsQuery, ToolDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ToolDetailsQuery, ToolDetailsQueryVariables>(ToolDetailsDocument, options);
        }
export type ToolDetailsQueryHookResult = ReturnType<typeof useToolDetailsQuery>;
export type ToolDetailsLazyQueryHookResult = ReturnType<typeof useToolDetailsLazyQuery>;
export type ToolDetailsQueryResult = Apollo.QueryResult<ToolDetailsQuery, ToolDetailsQueryVariables>;
export const ToolsPagedDocument = gql`
    query ToolsPaged($skip: Float, $take: Float, $sortBy: String, $sortDirection: SortDirection, $excludeIds: [String!], $includeIds: [String!], $tagIds: [String!], $includeOwned: Boolean, $userIds: [String!]) {
  toolsPaged(
    skip: $skip
    take: $take
    sortBy: $sortBy
    sortDirection: $sortDirection
    excludeIds: $excludeIds
    includeIds: $includeIds
    tagIds: $tagIds
    includeOwned: $includeOwned
    userIds: $userIds
  ) {
    records {
      ...ToolCard
    }
    count
  }
}
    ${ToolCardFragmentDoc}`;

/**
 * __useToolsPagedQuery__
 *
 * To run a query within a React component, call `useToolsPagedQuery` and pass it any options that fit your needs.
 * When your component renders, `useToolsPagedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useToolsPagedQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      excludeIds: // value for 'excludeIds'
 *      includeIds: // value for 'includeIds'
 *      tagIds: // value for 'tagIds'
 *      includeOwned: // value for 'includeOwned'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useToolsPagedQuery(baseOptions?: Apollo.QueryHookOptions<ToolsPagedQuery, ToolsPagedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ToolsPagedQuery, ToolsPagedQueryVariables>(ToolsPagedDocument, options);
      }
export function useToolsPagedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ToolsPagedQuery, ToolsPagedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ToolsPagedQuery, ToolsPagedQueryVariables>(ToolsPagedDocument, options);
        }
export type ToolsPagedQueryHookResult = ReturnType<typeof useToolsPagedQuery>;
export type ToolsPagedLazyQueryHookResult = ReturnType<typeof useToolsPagedLazyQuery>;
export type ToolsPagedQueryResult = Apollo.QueryResult<ToolsPagedQuery, ToolsPagedQueryVariables>;
export const CreateAndReleaseToolDocument = gql`
    mutation CreateAndReleaseTool($data: CreateToolAndReleaseDTO!) {
  toolMutations {
    createAndRelease(data: $data) {
      ...ToolCard
    }
  }
}
    ${ToolCardFragmentDoc}`;
export type CreateAndReleaseToolMutationFn = Apollo.MutationFunction<CreateAndReleaseToolMutation, CreateAndReleaseToolMutationVariables>;

/**
 * __useCreateAndReleaseToolMutation__
 *
 * To run a mutation, you first call `useCreateAndReleaseToolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAndReleaseToolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAndReleaseToolMutation, { data, loading, error }] = useCreateAndReleaseToolMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAndReleaseToolMutation(baseOptions?: Apollo.MutationHookOptions<CreateAndReleaseToolMutation, CreateAndReleaseToolMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAndReleaseToolMutation, CreateAndReleaseToolMutationVariables>(CreateAndReleaseToolDocument, options);
      }
export type CreateAndReleaseToolMutationHookResult = ReturnType<typeof useCreateAndReleaseToolMutation>;
export type CreateAndReleaseToolMutationResult = Apollo.MutationResult<CreateAndReleaseToolMutation>;
export type CreateAndReleaseToolMutationOptions = Apollo.BaseMutationOptions<CreateAndReleaseToolMutation, CreateAndReleaseToolMutationVariables>;
export const UpdatedToolDocument = gql`
    mutation UpdatedTool($data: UpdateToolDTO!, $toolId: String!) {
  toolMutations {
    update(data: $data, toolId: $toolId) {
      ...ToolDetails
    }
  }
}
    ${ToolDetailsFragmentDoc}`;
export type UpdatedToolMutationFn = Apollo.MutationFunction<UpdatedToolMutation, UpdatedToolMutationVariables>;

/**
 * __useUpdatedToolMutation__
 *
 * To run a mutation, you first call `useUpdatedToolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatedToolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatedToolMutation, { data, loading, error }] = useUpdatedToolMutation({
 *   variables: {
 *      data: // value for 'data'
 *      toolId: // value for 'toolId'
 *   },
 * });
 */
export function useUpdatedToolMutation(baseOptions?: Apollo.MutationHookOptions<UpdatedToolMutation, UpdatedToolMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatedToolMutation, UpdatedToolMutationVariables>(UpdatedToolDocument, options);
      }
export type UpdatedToolMutationHookResult = ReturnType<typeof useUpdatedToolMutation>;
export type UpdatedToolMutationResult = Apollo.MutationResult<UpdatedToolMutation>;
export type UpdatedToolMutationOptions = Apollo.BaseMutationOptions<UpdatedToolMutation, UpdatedToolMutationVariables>;
export const RemoveToolDocument = gql`
    mutation RemoveTool($toolId: String!) {
  toolMutations {
    remove(toolId: $toolId)
  }
}
    `;
export type RemoveToolMutationFn = Apollo.MutationFunction<RemoveToolMutation, RemoveToolMutationVariables>;

/**
 * __useRemoveToolMutation__
 *
 * To run a mutation, you first call `useRemoveToolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveToolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeToolMutation, { data, loading, error }] = useRemoveToolMutation({
 *   variables: {
 *      toolId: // value for 'toolId'
 *   },
 * });
 */
export function useRemoveToolMutation(baseOptions?: Apollo.MutationHookOptions<RemoveToolMutation, RemoveToolMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveToolMutation, RemoveToolMutationVariables>(RemoveToolDocument, options);
      }
export type RemoveToolMutationHookResult = ReturnType<typeof useRemoveToolMutation>;
export type RemoveToolMutationResult = Apollo.MutationResult<RemoveToolMutation>;
export type RemoveToolMutationOptions = Apollo.BaseMutationOptions<RemoveToolMutation, RemoveToolMutationVariables>;
export const AddToolReviewDocument = gql`
    mutation AddToolReview($input: CreateReviewDTO!, $toolId: String!) {
  toolMutations {
    addReview(input: $input, toolId: $toolId) {
      id
      rating
      hasReviewFromCurrentUser
    }
  }
}
    `;
export type AddToolReviewMutationFn = Apollo.MutationFunction<AddToolReviewMutation, AddToolReviewMutationVariables>;

/**
 * __useAddToolReviewMutation__
 *
 * To run a mutation, you first call `useAddToolReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToolReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToolReviewMutation, { data, loading, error }] = useAddToolReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *      toolId: // value for 'toolId'
 *   },
 * });
 */
export function useAddToolReviewMutation(baseOptions?: Apollo.MutationHookOptions<AddToolReviewMutation, AddToolReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddToolReviewMutation, AddToolReviewMutationVariables>(AddToolReviewDocument, options);
      }
export type AddToolReviewMutationHookResult = ReturnType<typeof useAddToolReviewMutation>;
export type AddToolReviewMutationResult = Apollo.MutationResult<AddToolReviewMutation>;
export type AddToolReviewMutationOptions = Apollo.BaseMutationOptions<AddToolReviewMutation, AddToolReviewMutationVariables>;
export const UserAccountDocument = gql`
    query UserAccount($userId: String!) {
  user(userId: $userId) {
    nickname
  }
}
    `;

/**
 * __useUserAccountQuery__
 *
 * To run a query within a React component, call `useUserAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAccountQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserAccountQuery(baseOptions: Apollo.QueryHookOptions<UserAccountQuery, UserAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserAccountQuery, UserAccountQueryVariables>(UserAccountDocument, options);
      }
export function useUserAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserAccountQuery, UserAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserAccountQuery, UserAccountQueryVariables>(UserAccountDocument, options);
        }
export type UserAccountQueryHookResult = ReturnType<typeof useUserAccountQuery>;
export type UserAccountLazyQueryHookResult = ReturnType<typeof useUserAccountLazyQuery>;
export type UserAccountQueryResult = Apollo.QueryResult<UserAccountQuery, UserAccountQueryVariables>;
export const UserToolsOverviewDocument = gql`
    query UserToolsOverview($userIds: [String!]!) {
  toolsOverview(userIds: $userIds) {
    viewsTotal
    ratingAverage
    requestsTotal
  }
}
    `;

/**
 * __useUserToolsOverviewQuery__
 *
 * To run a query within a React component, call `useUserToolsOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserToolsOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserToolsOverviewQuery({
 *   variables: {
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useUserToolsOverviewQuery(baseOptions: Apollo.QueryHookOptions<UserToolsOverviewQuery, UserToolsOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserToolsOverviewQuery, UserToolsOverviewQueryVariables>(UserToolsOverviewDocument, options);
      }
export function useUserToolsOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserToolsOverviewQuery, UserToolsOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserToolsOverviewQuery, UserToolsOverviewQueryVariables>(UserToolsOverviewDocument, options);
        }
export type UserToolsOverviewQueryHookResult = ReturnType<typeof useUserToolsOverviewQuery>;
export type UserToolsOverviewLazyQueryHookResult = ReturnType<typeof useUserToolsOverviewLazyQuery>;
export type UserToolsOverviewQueryResult = Apollo.QueryResult<UserToolsOverviewQuery, UserToolsOverviewQueryVariables>;
import { SortDirection } from '../queries/types'

export const AUTH_TOKEN_STORAGE_KEY = 'auth-token'
export const DEFAULT_PROVIDER_NAME = 'tbox'
export const PASSWORD_MIN_LENGTH = 8
export const MIN_TOOL_REQUEST_PRICE = 0.00001
export const MAX_TOOL_REQUEST_PRICE = 100
export const USER_DEFAULT_FUNDS = 20

export enum SortType {
  PriceHighToLow = 'sort.priceHighToLow',
  PriceLowToHigh = 'sort.priceLowToHigh',
  RatingHighToLow = 'sort.ratingHighToLow',
  RatingLowToHigh = 'sort.ratingLowToHigh',
  RecentlyUpdated = 'sort.recentlyUpdated',
  NameAZ = 'sort.nameAz',
  NameZA = 'sort.nameZa',
}

interface Variable {
  sortBy: string
  sortDirection: SortDirection
}

export const MAP_TOOLS_SORT_TYPE_TO_VARIABLE: { [key in SortType]: Variable } =
  {
    [SortType.RatingLowToHigh]: {
      sortBy: 'rating',
      sortDirection: SortDirection.Asc,
    },
    [SortType.RatingHighToLow]: {
      sortBy: 'rating',
      sortDirection: SortDirection.Desc,
    },
    [SortType.PriceLowToHigh]: {
      sortBy: 'price',
      sortDirection: SortDirection.Asc,
    },
    [SortType.PriceHighToLow]: {
      sortBy: 'price',
      sortDirection: SortDirection.Desc,
    },
    [SortType.RecentlyUpdated]: {
      sortBy: 'updatedAt',
      sortDirection: SortDirection.Desc,
    },
    [SortType.NameAZ]: {
      sortBy: 'name',
      sortDirection: SortDirection.Asc,
    },
    [SortType.NameZA]: {
      sortBy: 'name',
      sortDirection: SortDirection.Desc,
    },
  }

import { Box } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'
import { Footer } from './Footer/Footer'
import { getConditionalObj } from '../../utils/getConditionalObj'
import { useResolutions } from '../../hooks/useResolutions'
import { HeaderMobile } from './Header/HeaderMobile/HeaderMobile'
import { HeaderDesktop } from './Header/HeaderDesktop/HeaderDesktop'
import { HeaderDesktopBackground } from './Header/HeaderDesktop/HeaderDesktopBackground'
import { HeaderSimple } from './Header/HeaderSimple/HeaderSimple'

interface Props {
  headerDesktopPosition?: 'fixed' | 'absolute'
  disablePadding?: boolean
  disablePaddingBottomOnMobile?: boolean
  fixedFullScreenHeight?: boolean
  children?: React.ReactElement | React.ReactElement[]
}

export const MainLayout: React.FC<Props> = ({
  headerDesktopPosition = 'absolute',
  disablePadding = false,
  disablePaddingBottomOnMobile = false,
  fixedFullScreenHeight = false,
  children,
}) => {
  const { isMobileResolution } = useResolutions()
  return (
    <Box>
      <HeaderSimple position={headerDesktopPosition} />
      <Box
        component="main"
        sx={{
          minHeight: '100vh',
          ...getConditionalObj(!disablePadding, {
            pt: {
              xs: 6,
              sm: 23,
            },
            pb: {
              xs: disablePaddingBottomOnMobile ? 0 : 10,
              sm: 14,
            },
          }),
          ...getConditionalObj(fixedFullScreenHeight && isMobileResolution, {
            height: '1px',
            boxSizing: 'content-box',
          }),
        }}
      >
        {children || <Outlet />}
      </Box>
    </Box>
  )
}

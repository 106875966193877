import { Button, Stack, Typography } from '@mui/material'

export const ErrorFallback: React.FC = () => {
  const handleRefreshClick = (): void => {
    window.location.reload()
  }

  return (
    <Stack alignItems="center" justifyContent="center" sx={{ p: 5 }}>
      <Typography variant="h4">Something went wrong!</Typography>
      <Button onClick={handleRefreshClick}>Refresh</Button>
    </Stack>
  )
}

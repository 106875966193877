import { useContext } from 'react'
import { type AuthContextInterface } from '../context/AuthContext/AuthContext'
import { CognitoAuthContext } from '../context/AuthContext/CognitoAuthContext'

export const useCognitoAuthContext = (): AuthContextInterface => {
  const congiutoAuthContext = useContext(CognitoAuthContext)

  if (!congiutoAuthContext) {
    throw new Error('CognitoAuthContext is undefined')
  }

  return congiutoAuthContext
}

import { CircularProgress } from '@mui/material'
import { Stack } from '@mui/system'

interface Props {
  fullScreen?: boolean
  size?: number
}

export const PageLoader: React.FC<Props> = ({
  fullScreen = false,
  size = 80,
}) => {
  return (
    <Stack
      height={fullScreen ? '100vh' : '100%'}
      p={fullScreen ? '0px !important' : undefined}
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress size={size} />
    </Stack>
  )
}

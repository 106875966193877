import {
  type AttributeType,
  CognitoIdentityProviderClient,
} from '@aws-sdk/client-cognito-identity-provider'

export const createCognitoIdentityServiceProvider =
  (): CognitoIdentityProviderClient => {
    const { REACT_APP_AWS_REGION } = process.env
    if (!REACT_APP_AWS_REGION) {
      throw new Error(
        'AWS region name for Cognito Identity Service Provider is not defined',
      )
    }
    return new CognitoIdentityProviderClient({
      region: REACT_APP_AWS_REGION,
    })
  }

export const getCognitoClientID = (): string => {
  const { REACT_APP_AWS_COGNITO_CLIENT_ID } = process.env
  if (!REACT_APP_AWS_COGNITO_CLIENT_ID) {
    throw new Error('Cognito client ID is not defined')
  }

  return REACT_APP_AWS_COGNITO_CLIENT_ID
}

export const getCognitoUserAttributeValue = (
  UserAttributes: AttributeType[],
  name: string,
): string => {
  const value = UserAttributes?.find(({ Name }: any) => Name === name)?.Value

  if (value === undefined) {
    throw new Error(`Attribute "${name}" is not found on user model`)
  }

  return value
}

import { Navigate, useLocation, useRoutes } from 'react-router-dom'
import { lazily } from 'react-lazily'

// CLI-PAGE-IMPORT
import {
  getMainPageRoute,
  getNotFoundRoute,
  getLoginRoute,
  getSignUpRoute,
  getPrivacyPageRoute,
  getChatPageRoute,
  getContactPageRoute,
  getToolsPageRoute,
  getToolDetailsPageRoute,
  getCreateAndReleaseToolPageRoute,
  getUpdateToolPageRoute,
  getToolReleasesPageRoute,
  getUserApiKeysPageRoute,
  getUserSettingsPageRoute,
  getUserToolsPageRoute,
  getUserInfoPageRoute,
  getCareersPageRoute,
  getOtherPageRoute,
  getForgotPasswordPageRoute,
  getWaitlistPageRoute,
  // CLI-ROUTE-IMPORT
} from './index'
import { AuthGuard } from '../guards/AuthGuard/AuthGuard'
import { GuestGuard } from '../guards/GuestGuard/GuestGuard'
import { useEffect } from 'react'
import { MainLayout } from '../layout/MainLayout/MainLayout'

const { WaitlistPage } = lazily(
  async () => await import('../pages/WaitlistPage/WaitlistPage'),
)

const { OtherPage } = lazily(
  async () => await import('../pages/OtherPage/OtherPage'),
)
const { CareersPage } = lazily(
  async () => await import('../pages/CareersPage/CareersPage'),
)
const { CurrentUserGuard } = lazily(
  async () => await import('../guards/CurrentUserGuard/CurrentUserGuard'),
)
const { UserInfoPage } = lazily(
  async () => await import('../pages/UserInfoPage/UserInfoPage'),
)
const { UserSettingsPage } = lazily(
  async () => await import('../pages/UserSettingsPage/UserSettingsPage'),
)
const { UserApiKeysPage } = lazily(
  async () => await import('../pages/UserApiKeysPage/UserApiKeysPage'),
)
const { UserToolsPage } = lazily(
  async () => await import('../pages/UserToolsPage/UserToolsPage'),
)
const { UserPageLayout } = lazily(
  async () => await import('../layout/UserPageLayout/UserPageLayout'),
)
const { ToolsReleasesPage } = lazily(
  async () => await import('../pages/ToolReleasesPage/ToolReleasesPage'),
)
const { ToolsPage } = lazily(
  async () => await import('../pages/ToolsPage/ToolsPage'),
)
const { ContactPage } = lazily(
  async () => await import('../pages/ContactPage/ContactPage'),
)
const { ChatPage } = lazily(
  async () => await import('../pages/ChatPage/ChatPage'),
)
const { ForgotPasswordPage } = lazily(
  async () => await import('../pages/ForgotPasswordPage/ForgotPasswordPage'),
)
const { UpdateToolPage } = lazily(
  async () => await import('../pages/ToolFormPages/UpdateToolPage'),
)
const { CreateAndReleaseToolPage } = lazily(
  async () => await import('../pages/ToolFormPages/CreateAndReleaseToolPage'),
)
const { ToolDetailsPage } = lazily(
  async () => await import('../pages/ToolDetailsPage/ToolDetailsPage'),
)
const { PrivacyPage } = lazily(
  async () => await import('../pages/PrivacyPage/PrivacyPage'),
)
const { MainPage } = lazily(
  async () => await import('../pages/MainPage/MainPage'),
)
const { NotFoundPage } = lazily(
  async () => await import('../pages/NotFoundPage/NotFoundPage'),
)
const { LoginPage } = lazily(
  async () => await import('../pages/AuthPages/LoginPage'),
)
const { SignUpPage } = lazily(
  async () => await import('../pages/AuthPages/SignUpPage'),
)

export const Routes: React.FC = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return useRoutes([
    {
      children: [
        {
          element: <WaitlistPage />,
          path: getMainPageRoute.path,
        },
      ],
      element: <MainLayout headerDesktopPosition="fixed" />,
      path: getMainPageRoute.path,
    },
  ])
}

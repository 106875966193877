import { useContext } from 'react'
import {
  ThemeSettingsContext,
  type ThemeSettingsContextInterface,
} from '../context/ThemeSettingsContext'

export const useThemeSettings = (): ThemeSettingsContextInterface => {
  const theme = useContext(ThemeSettingsContext)

  if (!theme) {
    throw new Error(
      'Something went wrong with theme generation! Please check ThemeContext.tsx',
    )
  }

  return theme
}

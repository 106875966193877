import { lighten, darken, type PaletteOptions } from '@mui/material'

export const lightPalette: PaletteOptions = {
  background: {
    default: '#ffffff',
    paper: lighten('#F4F2FF', 0.3),
  },
  primary: {
    dark: darken('#567476', 0.3),
    light: '#D8D4FA',
    main: '#A570FA',
  },
  secondary: {
    dark: darken('#23222D', 0.3),
    light: lighten('#23222D', 0.3),
    main: '#23222D',
    contrastText: '#ffffff',
  },
  info: {
    main: '#519FE7',
  },
  warning: {
    main: '#FFB803',
  },
  success: {
    main: '#4BC684',
  },
  error: {
    main: '#F05C5C',
  },
  text: {
    primary: '#000000',
    secondary: '#6f6379',
  },
}

import { createContext, useEffect, useState } from 'react'
import { type AuthContextInterface, type UserInterface } from './AuthContext'
import {
  ConfirmSignUpCommand,
  GetUserCommand,
  ResendConfirmationCodeCommand,
  InitiateAuthCommand,
  UpdateUserAttributesCommand,
  VerifyUserAttributeCommand,
  SignUpCommand,
  ChangePasswordCommand,
  ForgotPasswordCommand,
  ConfirmForgotPasswordCommand,
} from '@aws-sdk/client-cognito-identity-provider'
import { useLocalStorage } from 'usehooks-ts'
import {
  createCognitoIdentityServiceProvider,
  getCognitoClientID,
  getCognitoUserAttributeValue,
} from '../../utils/aws/cognito'
import { useNavigate } from 'react-router-dom'
import { getMainPageRoute } from '../../routes'

const clientID = getCognitoClientID()
const cognitoIdentityServiceProvider = createCognitoIdentityServiceProvider()
export const CognitoAuthContext = createContext<AuthContextInterface | null>(
  null,
)

interface Props {
  children: React.ReactElement
  fallback?: any
}

export const CognitoAuthProvider: React.FC<Props> = ({
  fallback,
  children,
}) => {
  const [token, setToken] = useLocalStorage<string | null>('auth-token', '')
  const [currentUser, setCurrentUser] = useState<UserInterface | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()

  const getUser = async (token: string): Promise<UserInterface | null> => {
    const command = new GetUserCommand({
      AccessToken: token,
    })
    const { UserAttributes = [] } = await cognitoIdentityServiceProvider.send(
      command,
    )
    return {
      id: getCognitoUserAttributeValue(UserAttributes, 'sub'),
      email: getCognitoUserAttributeValue(UserAttributes, 'email'),
      nickname: getCognitoUserAttributeValue(UserAttributes, 'nickname'),
      funds: Number(
        getCognitoUserAttributeValue(UserAttributes, 'custom:funds'),
      ),
    }
  }

  const logout = (): void => {
    setToken('')
    navigate(getMainPageRoute.path)
  }

  useEffect(() => {
    if (!token) {
      setCurrentUser(null)
      setIsLoading(false)
    } else {
      setIsLoading(true)
      getUser(token)
        .then(user => {
          setCurrentUser(user)
        })
        .catch(() => {
          logout()
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [token])

  const refetchUser = async (): Promise<void> => {
    setToken(token)
  }

  const login = async (email: string, password: string): Promise<void> => {
    const command = new InitiateAuthCommand({
      AuthFlow: 'USER_PASSWORD_AUTH',
      AuthParameters: {
        USERNAME: email,
        PASSWORD: password,
      },
      ClientId: clientID,
    })
    const data = await cognitoIdentityServiceProvider.send(command)
    const token = data.AuthenticationResult?.AccessToken as string
    setToken(token)
  }

  const signUp = async (
    email: string,
    password: string,
    attributes: Array<{ name: string; value: string }> = [],
  ): Promise<void> => {
    const command = new SignUpCommand({
      ClientId: clientID,
      Username: email,
      Password: password,
      UserAttributes: attributes.map(({ name, value }) => ({
        Name: name,
        Value: value,
      })),
    })
    await cognitoIdentityServiceProvider.send(command)
  }

  const resendConfirmation = async (email: string): Promise<void> => {
    const command = new ResendConfirmationCodeCommand({
      Username: email,
      ClientId: clientID,
    })
    await cognitoIdentityServiceProvider.send(command)
  }

  const changeAttributes = async (
    attributes: Array<{ name: string; value: string }> = [],
  ): Promise<void> => {
    if (!token) {
      throw new Error('User is not authorised')
    }
    const command = new UpdateUserAttributesCommand({
      AccessToken: token,
      UserAttributes: attributes.map(({ name, value }) => ({
        Name: name,
        Value: value,
      })),
    })
    await cognitoIdentityServiceProvider.send(command)
  }

  const confirmSignUp = async (email: string, code: string): Promise<void> => {
    const command = new ConfirmSignUpCommand({
      ClientId: clientID,
      Username: email,
      ConfirmationCode: code,
    })
    await cognitoIdentityServiceProvider.send(command)
  }

  const confirmAttributeChange = async (
    attribute: string,
    code: string,
  ): Promise<void> => {
    if (!token) {
      throw new Error('User is not authorised')
    }
    const command = new VerifyUserAttributeCommand({
      AccessToken: token,
      AttributeName: attribute,
      Code: code,
    })
    await cognitoIdentityServiceProvider.send(command)
  }

  const changePassword = async (
    currentPassword: string,
    newPassword: string,
  ): Promise<void> => {
    if (!token) {
      throw new Error('User is not authorised')
    }
    const command = new ChangePasswordCommand({
      AccessToken: token,
      PreviousPassword: currentPassword,
      ProposedPassword: newPassword,
    })
    await cognitoIdentityServiceProvider.send(command)
  }

  const forgotPassword = async (email: string): Promise<void> => {
    const command = new ForgotPasswordCommand({
      ClientId: clientID,
      Username: email,
    })
    await cognitoIdentityServiceProvider.send(command)
  }

  const confirmForgotPassword = async (
    email: string,
    code: string,
    password: string,
  ): Promise<void> => {
    const command = new ConfirmForgotPasswordCommand({
      ClientId: clientID,
      Username: email,
      Password: password,
      ConfirmationCode: code,
    })
    await cognitoIdentityServiceProvider.send(command)
  }

  if (isLoading) {
    return fallback || null
  }

  return (
    <CognitoAuthContext.Provider
      value={{
        isAuthorized: !!currentUser,
        changeAttributes,
        confirmAttributeChange,
        login,
        logout,
        signUp,
        confirmSignUp,
        confirmForgotPassword,
        forgotPassword,
        currentUser,
        resendConfirmation,
        refetchUser,
        changePassword,
      }}
    >
      {children}
    </CognitoAuthContext.Provider>
  )
}
